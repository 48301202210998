import React from "react";
interface RequestObjContext {
  payload?: ConversationObjectType[];
  updatePayload?: (latest: ConversationObjectType[]) => void;
}

export const ChatRequestPayloadContext = React.createContext<RequestObjContext>(
  {}
);

export interface ConversationObjectType {
  role: string;
  content: string;
}

export default function ChatRequestPayloadProvider({ children }: any) {
  const [payload, setPayload] = React.useState<ConversationObjectType[]>([]);
  function updatePayload(latest: ConversationObjectType[]) {
    setPayload(latest);
  }
  return (
    <ChatRequestPayloadContext.Provider value={{ payload, updatePayload }}>
      {children}
    </ChatRequestPayloadContext.Provider>
  );
}
