import React from "react";
import ChatbotKit from "react-chatbot-kit";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, IconButton } from "@mui/material";
import ThreePOutlinedIcon from "@mui/icons-material/ThreePOutlined";
// import SettingsIcon from "@mui/icons-material/Settings";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

import "./ChatSideSheet.style.scss";
import config from "../../bot/config";
import ActionProvider from "../../bot/ActionProvider";
import MessageParser from "../../bot/MessageParser";

export default function ChatSideSheet() {
  const [activeChat, setActiveChat] = React.useState(false);
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // const open = Boolean(anchorEl);
  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const onChatIconClick = () => setActiveChat(true);
  const onBackIconClick = () => setActiveChat(false);

  const saveMessages = (messages: string) => {
    localStorage.setItem("chat_messages", JSON.stringify(messages ?? {}));
  };

  const loadMessages = () => {
    const messages = JSON.parse(localStorage.getItem("chat_messages") ?? "{}");
    return messages;
  };

  return (
    <Box className="docking-container" sx={{ width: activeChat ? 370 : 60 }}>
      {activeChat ? (
        <ChatbotKit
          config={config}
          actionProvider={ActionProvider}
          messageParser={MessageParser}
          messageHistory={loadMessages()}
          saveMessages={saveMessages}
          runInitialMessagesWithHistory={true}
        />
      ) : (
        <Box sx={{ flex: 1 }}></Box>
      )}

      <Box className="buttons-container">
        {activeChat ? (
          <Box component="div" sx={{ display: "flex", width: "100%" }}>
            <IconButton onClick={() => onBackIconClick()}>
              <ChevronRightIcon fontSize="large" />
            </IconButton>
            <Box sx={{ flex: 1 }} />
            {/* <Tooltip title="Reset conversation">
              <IconButton onClick={(e) => handleRest()}>
                <RotateLeftIcon fontSize="medium" />
              </IconButton>
            </Tooltip> 
            <IconButton onClick={(e) => handleClick(e)}>
              <MoreHorizIcon fontSize="medium" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  // "&:before": {
                  //   content: '""',
                  //   display: "block",
                  //   position: "absolute",
                  //   top: 0,
                  //   right: 14,
                  //   width: 10,
                  //   height: 10,
                  //   bgcolor: "background.paper",
                  //   transform: "translateY(-50%) rotate(45deg)",
                  //   zIndex: 0,
                  // },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <HelpOutlineIcon fontSize="small" />
                </ListItemIcon>
                Help & FAQ
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <HighlightOffOutlinedIcon fontSize="small" />
                </ListItemIcon>
                Clear & Reset
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
            </Menu> */}
          </Box>
        ) : (
          <IconButton
            sx={{ margin: "auto" }}
            color="primary"
            onClick={() => onChatIconClick()}
          >
            <ThreePOutlinedIcon fontSize="large" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
