import React from "react";

export const ProductListContext = React.createContext<any>({});

export default function ProductListContextProvider({ children }: any) {
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [clearFilters, setClearFilters] = React.useState(false);

  function updateProducts(latest: any) {
    setProducts(latest);
  }
  return (
    <ProductListContext.Provider
      value={{
        products,
        updateProducts,
        loading,
        setLoading,
        clearFilters,
        setClearFilters,
      }}
    >
      {children}
    </ProductListContext.Provider>
  );
}
