import { Typography } from "@mui/material";

export default function UserChatMessage(props: any) {
  return (
    <Typography className="react-chatbot-kit-user-chat-message" component="div">
      <span style={{ fontSize: 14, color: "#000" }}>{props.message}</span>
      <Typography
        className="react-chatbot-kit-user-chat-message-arrow"
        component="div"
      ></Typography>
    </Typography>
  );
}
