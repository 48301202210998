// import { useContext } from "react";
import Typography from "@mui/material/Typography";
// import CloseIcon from "@mui/icons-material/Close";
// import IconButton from "@mui/material/IconButton";

import "./BotHeader.style.scss";
// import { BotVisibleContext } from "../../contexts/BotVisibleContext";
// import RestartAltIcon from "@mui/icons-material/RestartAlt";
// import { ChatRequestPayloadContext } from "../../contexts/ChatRequestPayloadContext";

export default function BotHeader(props: any) {
  // const { hideBot } = useContext(BotVisibleContext);
  // const { updatePayload } = useContext(ChatRequestPayloadContext);

  // const resetBot = () => {
  //   console.log("Reset Bot");
  //   updatePayload && updatePayload([]);
  // };

  return (
    <div className="bot-side-header">
      <Typography className="bot-header-title">ai.RETAIL Copilot</Typography>
      {/* <div style={{ flex: 1 }}></div>
      <IconButton onClick={() => resetBot()} aria-label="reset">
        <RestartAltIcon fontSize="small" style={{ color: "#fff" }} />
      </IconButton>
      <IconButton
        onClick={() => {
          hideBot();
        }}
        aria-label="close"
      >
        <CloseIcon fontSize="small" style={{ color: "#fff" }} />
      </IconButton> */}
    </div>
  );
}
