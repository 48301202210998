import { createContext, useState } from "react";

// Create a new context
// TODO: Fix type issues with help of https://stackoverflow.com/questions/61333188/react-typescript-avoid-context-default-value
export const BotVisibleContext = createContext<any>({} as any);

// Define a component that will hold the state
export const BotVisibleProvider = ({ children }: any) => {
  const [isVisible, setVisible] = useState(false);

  const showBot = () => setVisible(true);

  const hideBot = () => setVisible(false);

  return (
    <BotVisibleContext.Provider value={{ isVisible, showBot, hideBot }}>
      {children}
    </BotVisibleContext.Provider>
  );
};
