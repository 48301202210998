import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { ProductObjType } from "../../types/products";
import { Rating } from "@mui/material";

import "./Product.style.scss";
import ImageNotFound from "../../img/Image_not_available.png";
// import { ImageNotFoundBase64 } from "../../utils/product_utils";

export default function Product(props: ProductObjType) {
  const image = props.url !== "" ? props?.url : ImageNotFound;
  // const image = props.image !== "" ? props?.image : ImageNotFoundBase64;

  return (
    <Card
      sx={{
        minWidth: 280,
        width: 280,
        height: 430,
      }}
      className={props.suggested ? "recommended-product" : "product"}
    >
      <CardMedia
        sx={{
          height: 280,
          backgroundSize: "100%",
          backgroundColor: "#ecebe9",
        }}
        image={`${image}`}
        // image={`data:image/png;base64, ${image}`}
        title={props.productName}
      ></CardMedia>
      <CardContent className="product-info" sx={{ textAlign: "center" }}>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: 20,
            fontWeight: 500,
            whiteSpace: "nowrap",
          }}
          gutterBottom
          component="p"
        >
          {props.productName}
        </Typography>
        <Typography
          component="p"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: 18,
            fontWeight: 400,
            whiteSpace: "nowrap",
          }}
        >
          {props.productDescription}
        </Typography>
        <Rating name="read-only" value={props.rating} readOnly />
        <Typography
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Typography sx={{ fontSize: 22 }}>
            ${Math.round(props.price)}
          </Typography>
          <Typography style={{ paddingLeft: 8 }}>
            ({props.discount_label})
          </Typography>
        </Typography>
        {props.additionalOffer && (
          <Typography
            style={{ fontSize: 12, paddingBottom: 8, color: "cadetblue" }}
          >
            {props.additionalOffer}
          </Typography>
        )}
        {/* {props.suggested && (
          <Typography
            sx={{
              background: "rgb(243 45 98)",
              color: "#fff",
              padding: "4px",
              borderRadius: "4px",
              width: 154,
              margin: "auto",
              fontSize: 14,
              //background: #CC0C39;
            }}
          >
            Most Suggested Item
          </Typography>
        )} */}
      </CardContent>
    </Card>
  );
}
