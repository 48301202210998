import { createChatBotMessage } from "react-chatbot-kit";
import BotHeader from "../components/bot-header/BotHeader";
import BotChatMessage from "../components/bot-chat-message/BotChatMessage";
import BotAvatar from "../components/bot-avatar/BotAvatar";
import UserChatMessage from "../components/user-chat-message/UserChatMessage";
import UserAvatar from "../components/user-avatar/UserAvatar";

const config = {
  initialMessages: [createChatBotMessage(`Hello Steven !`)],
  // Defines an object of custom components that will replace the stock chatbot components.
  customComponents: {
    // Replaces the default header
    header: (props) => <BotHeader {...props} />,
    botChatMessage: (props) => <BotChatMessage {...props} />,
    botAvatar: (props) => <BotAvatar {...props} />,
    // Replaces the default user icon
    userAvatar: (props) => <UserAvatar {...props} />,
    // Replaces the default user chat message
    userChatMessage: (props) => <UserChatMessage {...props} />,
  },
};

export default config;
