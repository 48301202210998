import React from "react";
// import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import "react-chatbot-kit/build/main.css";

import "./App.scss";
import { theme } from "./styles/theme";
// import Chatbot from "./views/chatbot/Chatbot";
import Products from "./views/products/Products";
// import SearchBar from "./components/searchbar/SearchBar";
import ResponsiveAppBar from "./components/appbar/Appbar";
import { BotVisibleProvider } from "./contexts/BotVisibleContext";
import ChatRequestPayloadProvider from "./contexts/ChatRequestPayloadContext";
import ProductListContextProvider from "./contexts/ProductListContext";
import ChatSideSheet from "./views/chat-side-sheet/ChatSideSheet";
import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import {  MsalProvider } from '@azure/msal-react';
import { AuthenticatedTemplate } from '@azure/msal-react';

const msalConfig = {
  auth: {
    clientId: '359a9150-b407-44e5-83db-ee0a895acacc',
    authority: `https://login.microsoftonline.com/30af61e6-f207-4ecc-97ac-2932bc0503dc`,
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage', // or 'sessionStorage'
    storeAuthStateInCookie: false,
  },
};

const pca = new PublicClientApplication(msalConfig);
type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  // const [searchQuery, setSearchQuery] = React.useState("");

  return (
    <MsalProvider instance={pca}>
      <AuthenticatedTemplate>
        <ThemeProvider theme={theme}>
          <ProductListContextProvider>
            <div className="top-container">
              <ResponsiveAppBar />
              {/* <div className="search-container">
                <SearchBar
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                />
                <Typography component="div" sx={{ width: 20 }} />
                <Button variant="contained" startIcon={<FilterAltOffIcon />}>
                  Clear Filters
                </Button>
              </div> */}
            </div>
            <Box className="center-panel">
              <Products />
              <BotVisibleProvider>
                <ChatRequestPayloadProvider>
                  <ChatSideSheet />
                </ChatRequestPayloadProvider>
              </BotVisibleProvider>
              {/* <BotVisibleProvider>
              <ChatRequestPayloadProvider>
                <Chatbot />
              </ChatRequestPayloadProvider>
            </BotVisibleProvider> */}
            </Box>
          </ProductListContextProvider>
        </ThemeProvider>
      </AuthenticatedTemplate>
    </MsalProvider>
  );
}

export default App;
